import {Link} from 'gatsby'
import React from 'react'
import styles from './experienceTimeline.module.css'

const ExperienceTimeline = () => (
    <div className={styles.root}>
      <div className={styles.jobItem}>
        <div className={styles.jobDates}>APRIL 2021 - CURRENT</div>
        <div className={styles.jobContent}>
          <h4 className={styles.jobTitle}>Senior Front End Staff Engineer</h4>
          <div className={styles.jobLocation}>THE BEACHBODY COMPANY - EL SEGUNDO, CA</div>
          <div className="job-description">
            <p>Senior Staff Engineer primarily tasked with modernizing and improving the Beach Body front-end tech stack. Helped lead development of the integration between Beach Body web login + checkout with the Myx Fitness Bike Hardware.</p>
              <ul className={styles.jobResponsibilities}>
                <li>Created internal NPM packages to componentize functionality to be leveraged across the various Beach Body web properties.</li>
                <li>Developed pages and functionality for the TeamBeachBody.com and BeachBody.com websites. </li>
                <li>Implemented jest unit testing and helped train other devs on writing tests.</li>
                <li>Performed code reviews.</li>
                <li>Implemented Optimizely A/B testing and feature flags in the react codebase.</li>
                <li>Integrated Contentful CMS.</li>
                <li>Diagnosed and fixed a large number of bugs and defects.</li>
                <li>Documented best practices and coding standards.</li>
                <li>Utilized GIT for version control </li>
              </ul>
          </div>
        </div>
      </div>
      <div className={styles.jobItem}>
        <div className={styles.jobDates}>OCT 2017 – APRIL 2021</div>
        <div className={styles.jobContent}>
          <h4 className={styles.jobTitle}>Head Of Digital</h4>
          <div className={styles.jobLocation}>THEWOOAGENCY.COM - CULVER CITY, CA</div>
          <div className="job-description">
            <p>Digital department manager charged with leading digital efforts across entire agency and helping to position agency as a “Digital agency”.</p>
              <ul className={styles.jobResponsibilities}>
                <li>Built new TheWoo.com website leveraging the WordPress rest API & React.js & Redux for the frontend. </li>
                <li>Lead and advise a team of three developers working on projects ranging from banner ads and websites to complex mobile and web applications. </li>
                <li>Concepted and led development of custom internal DXP (Digital Experience Platform) currently leveraged by numerous fortune 500 companies and powering experiences viewed by millions of visitors monthly. </li>
                <li>Instituted proper development and deployment workflows along with building documentation around internal processes. </li>
                <li>Led digitally focused client meetings including clients like LA Lakers, Almay, Lenovo, Erin Brockovich, Motorola and more. </li>
                <li>Created Gitlab CI/CD + Kubernetes deployment workflow and standardized docker based development environment for all digital projects. </li>
                <li>Utilized GIT for version control </li>
              </ul>
          </div>
        </div>
      </div>
      <div className={styles.jobItem}>
        <div className={styles.jobDates}>JAN 2016 – AUG 2017</div>
        <div className={styles.jobContent}>
          <h4 className={styles.jobTitle}>Senior Frontend Developer</h4>
          <div className={styles.jobLocation}>PANNACOOKING.COM - SANTA MONICA, CA </div>
          <div className="job-description">
            <p>Lead frontend developer responsible for all aspects of the sites frontend web presence including building out new features & sections of the site, handling third party integrations and also managing daily bug fixing across all platforms and devices as bugs are reported by our QA team.</p>
              <ul className={styles.jobResponsibilities}>
                <li>Built frontend of Pannacooking.com & extend backend dashboard using React.js & Redux. </li>
                <li>Cross platform browser testing and optimization of all web facing properties. </li>
                <li>Develop all projects in full collaboration with backend developers as we have a micro service architecture.</li>
                <li>Identify and then debug/trouble-shoot browser related issues.</li>
                <li>Built customized JWPlayer React component that operates and displays ui elements differently based upon current user’s subscription status for use across all areas of the site.</li>
                <li>Responsible for organizing and scheduling for all frontend related projects. </li>
                <li>Led build of v1 of Android App (Tablet + Mobile) using React Native</li>
                <li>Utilized GIT for version control</li>
              </ul>
          </div>
        </div>
      </div>
      <div className={styles.jobItem}>
        <div className={styles.jobDates}>OCT 2015 – DEC 2015</div>
        <div className={styles.jobContent}>
          <h4 className={styles.jobTitle}>Senior Frontend Developer</h4>
          <div className={styles.jobLocation}>THEWOOAGENCY.COM (CONTRACTOR) - CULVER CITY, CA</div>
          <div className="job-description">
            <p>Developed this website in a week for the new Lamborghini Huracan Ownership program. Was contracted by the Iris Agency in Atlanta to build the site from PSD.</p>
              <ul className={styles.jobResponsibilities}>
                <li>Built frontend of website out using Twitter Bootstrap, Backbone.js, jQuery & HTML5 / CSS3. </li>
                <li>Encoded and optimized HTML5 video files and coded an HTML5 video player to be played on page alongside the YouTube videos.</li>
                <li>Cross platform browser testing and optimization. </li>
                <li>Developed website with modern technologies to be fully responsive</li>
                <li>Identified and then debugged/troubleshot browser issues realted to the video player integrations required on site. </li>
                <li>Integrated with Google Spreadsheets as a basic but secure backend repository for form submissions.</li>
                <li>Utilized GIT for code revision control</li>
              </ul>
          </div>
        </div>
      </div>
      <div className={styles.jobItem}>
        <div className={styles.jobDates}>JULY 2015 – JULY 2015</div>
        <div className={styles.jobContent}>
          <h4 className={styles.jobTitle}>Senior Frontend Developer</h4>
          <div className={styles.jobLocation}>ENTERTHEHURACAN.COM (LAMBORGHINI) VIA IRIS WORLDWIDE (CONTRACTOR) - REMOTE</div>
          <div className="job-description">
            <p>Developed this website in a week for the new Lamborghini Huracan Ownership program. Was contracted by the Iris Agency in Atlanta to build the site from PSD.</p>
              <ul className={styles.jobResponsibilities}>
                <li>Built frontend of website out using Twitter Bootstrap, Backbone.js, jQuery & HTML5 / CSS3. </li>
                <li>Encoded and optimized HTML5 video files and coded an HTML5 video player to be played on page alongside the YouTube videos.</li>
                <li>Cross platform browser testing and optimization. </li>
                <li>Developed website with modern technologies to be fully responsive.</li>
                <li>Identified and then debugged/troubleshot browser issues realted to the video player integrations required on site. </li>
                <li>Integrated with Google Spreadsheets as a basic but secure backend repository for form submissions.</li>
                <li>Utilized GIT for code version control.</li>
              </ul>
          </div>
        </div>
      </div>
      <div className={styles.jobItem}>
        <div className={styles.jobDates}>FEB 2015 – MAY 2015</div>
        <div className={styles.jobContent}>
          <h4 className={styles.jobTitle}>Senior Frontend Developer</h4>
          <div className={styles.jobLocation}>FAMILYDOLLAR.COM VIA EPSILON & SAGEPATH (CONTRACTOR) - REMOTE</div>
          <div className="job-description">
            <p>Led team of three front-end developers working on FamilyDollar.com website rebuild using the Adobe AEM platform. Developed more than 30 Responsive “Sections” for the client to use in their daily site management. </p>
              <ul className={styles.jobResponsibilities}>
                <li>Built frontend of website out using Bootstrap, jQuery & Html 5 / CSS3 on the Adobe Experience Manager Platform.</li>
                <li>Developed and expanded existing user interface modules to be mobile responsive across large selection of devices and browsers.</li>
                <li>Cross platform browser testing and optimization including legacy browsers (ie8) and mobile devices.</li>
                <li>Identified and then debugged/trouble-shot existing issues with modules developed prior to my arrival.</li>
                <li>Collaborated with back-end developers to produce custom functionality for front-end integration.</li>
                <li>Utilized GIT for code version control.</li>
              </ul>
          </div>
        </div>
      </div>
      <div className={styles.jobItem}>
        <div className={styles.jobDates}>JULY 2013 – JUNE 2014</div>
        <div className={styles.jobContent}>
          <h4 className={styles.jobTitle}>Senior Frontend Developer</h4>
          <div className={styles.jobLocation}>TOMS.COM VIA DELOITTE DIGITAL & SAGEPATH (CONTRACTOR) - LOS ANGELES, CALIFORNIA </div>
          <div className="job-description">
            <p>Senior Frontend Developer on team of four front-end devs working on the Magento platform to build out the new high traffic Marketplace section of the TOMs.com website. Initial contract was extended from 3 months to 13 months. Promoted to the ATG Launch team after a successful launch on the marketplace project.</p>
              <ul className={styles.jobResponsibilities}>
                <li>Developed and customized Magento front-end templates using modern HTLM5, CSS3, and jQuery.</li>
                <li>Collaborated with Design team to create responsive pages and elements that functioned properly across the entire device landscape (mobile, tablet, etc.).</li>
                <li>Evaluated code to ensure that it functioned correctly across all platforms.</li>
                <li>Built ATG Page templates using JSP templates, a restful Json API and HTML5/CSS3/JavaScript & Foundation Framework.</li>
                <li>Cross platform browser testing and optimization including legacy browsers (ie8) and devices.</li>
                <li>Developed and expanded user interfaces to be mobile responsive across large selection of devices and browsers.</li>
                <li>Identified and then debugged/trouble-shot existing issues with Magento platform.</li>
                <li>Interacted alongside a back-end team to produce custom functionality for front-end integration.</li>
                <li>Extended contract as Front-end developer to the new Toms.com website on the ATG platform.</li>
                <li>Utilized GIT for code version control.</li>
                <li>Provide website maintenance and design enhancements.</li>
              </ul>
          </div>
        </div>
      </div>
      <div className={styles.jobItem}>
        <div className={styles.jobDates}>JUNE 2011 - AUG 2012</div>
        <div className={styles.jobContent}>
          <h4 className={styles.jobTitle}>Senior Lead Developer</h4>
          <div className={styles.jobLocation}>KONCEPT DESIGN STUDIO - ATLANTA, GEORGIA</div>
          <div className="job-description">
            <p>Directed all technical operations company wide. Oversaw web and mobile development, company backups, social media marketing initiatives and overall digital strategy. I supervised a team of junior developers and set all internal systems and standards with regards to technical operations for the entire company. Self managed and directed all company video production projects for numerous multi-family complexes in the Southeast United States.</p>
              <ul className={styles.jobResponsibilities}>
                <li>Front-end development in WordPress and .NET environments.</li>
                <li>Built an interactive apartment availability plugin for WordPress that interfaced with multiple 3rd party availability API services.</li>
                <li>Built and deployed a large scale WordPress multi-site network (300+ sites).</li>
                <li>Cross platform development with responsive design and development to support major browsers and platforms with graceful degradation.</li>
                <li>Developed and customized Magento, WordPress, and static websites both from scratch and leveraging existing theme frameworks. I also built our own internal theme framework specific to the Apartment Rental industry.</li>
                <li>Developed an advanced jQuery light box plugin for WordPress.</li>
                <li>Cross platform Browser testing and optimization.</li>
                <li>Worked alongside designer to produce outline for touchscreen interfaces.
                 and built backend api service to power Touchscreen tv experiences.</li>
                <li>Responsive development across desktop, mobile & Large Screen (TV) Interfaces.</li>
                <li>Debugged / Trouble shot in JavaScript and PHP.</li>
                <li>Shot Video w/ Canon 5d Mark2 DSLR & Edited Video using Premiere Pro. </li>
                <li>Led and mentored team of Jr. developers both onsite and overseas.</li>
              </ul>
          </div>
        </div>
      </div>
      <div className={styles.jobItem}>
        <div className={styles.jobDates}>JAN 2008 - MAY 2010</div>
        <div className={styles.jobContent}>
          <h4 className={styles.jobTitle}>DIRECTOR OF INTERNET TECHNOLOGIES</h4>
          <div className={styles.jobLocation}>SOUTHWINDS TECHNOLOGIES - ST. SIMONS ISLAND, GEORGIA</div>
          <div className="job-description">
            <p>Oversaw all technical support operations for 30+ employees, online product development, all web development and Internet marketing initiatives for our clients, company backups, SEO, social media marketing, and researching new technologies. </p>
              <ul className={styles.jobResponsibilities}>
                <li>Designed and developed mobile sites</li>
                <li>Developed and customized Magento, WordPress, Drupal, and Joomla themes from scratch and utilized existing theme frameworks.</li>
                <li>Built front-end templates for in house .Net web application.</li>
                <li>Filmed, produced & edited 100% of all video production projects for various Chamber & CVB clients in the Southeast.</li>
                <li>Updated outdated websites; transitioned all client websites to Joomla & WordPress CMS frameworks and evaluated new technologies so that company would have a competitive advantage.</li>
                <li>Browser testing/cross platform and optimization.</li>
                <li>Debugged / trouble shot JavaScript, jQuery and PHP.</li>
                <li>Managed day to day IT support and Website support.</li>
                <li>Instituted a company wide backup system and instituted a manageable support system. </li>
              </ul>
          </div>
        </div>
      </div>
      <div className={styles.jobItem}>
        <div className={styles.jobDates}>FEB 2006 - OCT 2017</div>
        <div className={styles.jobContent}>
          <h4 className={styles.jobTitle}>FOUNDER / LEAD DEVELOPER</h4>
          <div className={styles.jobLocation}>BRIGHT IDEA INTERACTIVE</div>
          <div className="job-description">
            <p>Supervise day to day operations including client interactions, project management, design/creative, programming, hosting infrastructure setup and management, social media and internet marketing, overseeing talent, and all other aspects of running a web design/development agency. </p>
              <ul className={styles.jobResponsibilities}>
                <li>Build highly custom WordPress multi-site environments with some powering in excess of 500+ websites. </li>
                <li>Develop and customize Magento, WordPress, Drupal and Joomla themes and plugins from scratch and utilizing existing theme/plugin frameworks.</li>
                <li>Cross platform development with Responsive Design and Development to support major browsers and platforms.</li>
                <li>Update & expand existing web applications that utilize modern frameworks like Ruby on Rails, Angular.js, Backbone.js, & Node.js. </li>
                <li>Mobile app development utilizing languages & Frameworks such as JavaScript, HTML5, CSS,  Phonegap, Angular.js and Ionic.</li>
                <li>Conducted cross platform browser testing and optimization.</li>
                <li>Debugging/troubleshooting in JavaScript and PHP.</li>
                <li>Utilize GIT for code version control.</li>
              </ul>
          </div>
        </div>
      </div>
    </div>
)

export default ExperienceTimeline
