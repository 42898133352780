import React from 'react'
import {graphql} from 'gatsby'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import ProjectPreviewGrid from '../components/project-preview-grid'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Hero from '../components/subPageHero'
import TechnologyIconList from '../components/technologyIconList'
import ExperienceTimeline from '../components/experienceTimeline'
import styles from '../styles/professional.page.css'

import heroBackground from "../assets/images/hero-background.jpg"

export const query = graphql`
  query ProfessionalPageQuery {
    site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
      title
      description
      keywords
    }
    projects: allSanityProject(
      limit: 6
      sort: {fields: [publishedAt], order: DESC}
      filter: {slug: {current: {ne: null}}, publishedAt: {ne: null}}
    ) {
      edges {
        node {
          id
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
            }
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`

const ProfessionalPage = props => {
  const {data, errors} = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const projectNodes = (data || {}).projects
    ? mapEdgesToNodes(data.projects)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <Container>
        <Hero heroBackground={{heroBackground}} heroText={'Professional Experience'} />
        <section className={'sectionBoxed'}>
          <div className={'introHeader'}>
            <h2>More than 15 years of experience doing what I love. </h2>
          </div>
            <p>Driven and creative professional with over 15 years of professional experience in all aspects of web and app development with a focus on the frontend user experience. Motivated team player and quick learner that has worked both remotely and onsite alongside development teams of all sizes to launch high traffic sites & web applications at the highest standards for well-known clients. Intimately familiar with the entire development process including initial project discovery, design, development and deployment with the added ability to quickly pick up new frameworks and technologies as needed. Have led numerous development teams and departments through successful launches and mentored junior level developers both onsite and remotely.
            </p>
            <TechnologyIconList headerText={'Tech I use:'} />
            <ExperienceTimeline />
        </section>
      </Container>
    </Layout>
  )
}

export default ProfessionalPage
