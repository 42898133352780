import {Link} from 'gatsby'
import React from 'react'
import {cn, buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import BlockText from './block-text'
import Img from 'gatsby-image'

import styles from './project-preview.module.css'
import {responsiveTitle3} from './typography.module.css'

function ProjectPreview (props) {
  return (
        <Link className={styles.root} to={`/project/${props.slug.current}`}>
          <div className={styles.card}>
            <div className={styles.leadMediaThumb}>
              {props.thumbImage && props.thumbImage.asset && (
                <Img fluid={props.thumbImage.asset.fluid} alt="background image" className={styles.cardImage}/>
              )}
            </div>
            <div className={styles.contentContainer}>
              <div className={styles.subHead}><span className={styles.subHeadStyle}>Case Study</span></div>
              <h3 className={styles.title}>{props.title}</h3>
              {props._rawExcerpt && (
                <div className={styles.excerpt}>
                  <BlockText blocks={props._rawExcerpt} />
                </div>
              )}
            </div>
          </div>
        </Link>

  )
}

export default ProjectPreview
