import {Link} from 'gatsby'
import React from 'react'
import Icon from './icon'
import {cn} from '../lib/helpers'
import techIcons from '../assets/images/tech-logos.png'

import styles from './technologyIconList.module.css'

const TechnologyIconList = ({headerText}) => (
    <div className={styles.root + ' clients-wrapper'}>
        <div className={styles.wrapper}>
            <h3 className={styles.sectionTitle} dangerouslySetInnerHTML={{__html: headerText}} />
            <img src={techIcons} className={styles.iconList} alt="Tech Logos" />
        </div>
    </div>
)

export default TechnologyIconList
